import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styles from "./BlogHeroHome.module.scss";

type BlogHeroHomeProps = {
  blogHero: Pick<
    Queries.WpPost,
    "author" | "featuredImage" | "title" | "uri" | "excerpt"
  >;
  hasNoBorder?: boolean;
  extraStyles?: React.CSSProperties;
};

const BlogHeroHome: React.FC<BlogHeroHomeProps> = ({
  blogHero,
  hasNoBorder = false,
  extraStyles = null,
}) => {
  const gatsbyImageData = blogHero?.featuredImage?.node?.gatsbyImage;
  const blogHeroClasses = hasNoBorder
    ? styles.blogHeroCat
    : `${styles.blogHeroCat} ${styles.border}`;

  return (
    <div className={blogHeroClasses}>
      <Link to={blogHero?.uri!}>
        <GatsbyImage
          loading="eager"
          className={`${styles.blogHeroCatImage} ${styles.showImg}`}
          image={gatsbyImageData!}
          alt={blogHero?.featuredImage?.node.altText || ""}
        />
      </Link>

      <Link to={blogHero?.uri!} className={styles.blogHeroLink}>
        <h2 className={styles.garamond}>{blogHero.title}</h2>
      </Link>
      <h3
        className={`${styles.garamond} ${styles.blogExcerpt}`}
        dangerouslySetInnerHTML={{ __html: blogHero?.excerpt! }}
      />
      <div className={styles.blogAuthorName}>
        <Link
          style={extraStyles!}
          to={blogHero?.author?.node?.uri!}
          title={`Posts by ${blogHero?.author?.node?.name!}`}
          rel="author"
          dangerouslySetInnerHTML={{ __html: blogHero?.author?.node.name! }}
        />
      </div>
    </div>
  );
};

export default BlogHeroHome;
