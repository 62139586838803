import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PollenPost } from "../../assets/Types";
import styles from "./BlogItem.module.scss";

interface IBlogItem {
  post: PollenPost;
}
const BlogItem: React.FC<IBlogItem> = ({ post }) => {
  const gatsbyImageData = post.featuredImage?.node?.localFile?.gatsbyImageData;

  return (
    <div data-testid="div:BlogItem" className={styles.blogItem}>
      <Link to={post.uri}>
        {gatsbyImageData ? (
          <GatsbyImage
            image={getImage(gatsbyImageData)}
            alt={post?.featuredImage?.node?.altText || ""}
          />
        ) : (
          <img
            data-src={post.featuredImage.node.localFile.publicURL}
            alt={post.featuredImage.node.altText}
            className={styles.blogItemImage}
            src={post.featuredImage.node.localFile.publicURL}
          />
        )}
      </Link>
      <div>
        <Link to={post.uri} className={styles.blogTitleLink}>
          <h2 className={styles.garamond}>{post.title}</h2>
        </Link>
        <p
          className={`${styles.garamond} ${styles.blogExcerpt}`}
          dangerouslySetInnerHTML={{ __html: post.excerpt }}
        />
        <div className={styles.authorName}>
          <Link to={post.author.node.uri}>{post.author.node.name}</Link>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
