import React from "react";
import { graphql, PageProps } from "gatsby";

import { getRandomInt } from "../../utils/getRandomInt";
import { PollenPost } from "../../assets/Types";
import { ProductAdQuery } from "../../assets/StaticQueries/ProductAd.query";
import DefaultPollenLayout from "../../layouts/DefaultPollenLayout";
import PostsByCategoryContainer from "../../containers/PostsByCategoryContainer/PostsByCategoryContainer";
import PostsNavSection from "../../containers/PostsNavSection/PostsNavSection";
import Seo from "gatsby-plugin-wpgraphql-seo";

type DataProps = {
  wpCategory: {
    id: string;
    uri: string;
    name: string;
    description: string;
  };
  allWpPost: {
    nodes: PollenPost[];
  };
  wp: any;
};

type PageContextProps = {
  currentPage: number;
  limit: number;
  numPages: number;
  skip: number;
  posts: PollenPost[];
  uri: string;
};

const CategoryTemplate: React.FC<PageProps<DataProps, PageContextProps>> = ({
  data,
  pageContext,
  location,
}) => {
  const {
    wpCategory,
    allWpPost: { nodes: posts },
    wp,
  } = data;
  const { currentPage, numPages, uri } = pageContext;
  const { allWpProductAd } = ProductAdQuery();
  const productAdIndex = getRandomInt(allWpProductAd.nodes.length);
  const productAdsC = allWpProductAd.nodes[productAdIndex];

  return (
    <DefaultPollenLayout pathname={location.pathname}>
      {wp.seo && (
        <Seo
          title={`${wpCategory.name} Archives - SimplePractice`}
          postSchema={wp.seo.contentTypes.post.schema.raw}
          post={{
            seo: {
              metaRobotsNoindex:
                process.env.GATSBY_ENVIRONMENT === "dev" ? "noindex" : "",
              metaRobotsNofollow:
                process.env.GATSBY_ENVIRONMENT === "dev" ? "nofollow" : "",
            },
          }}
        />
      )}
      <PostsByCategoryContainer
        posts={posts}
        productAdsC={productAdsC}
        category={wpCategory}
      />
      <PostsNavSection
        parentUrl={uri}
        currentPage={currentPage}
        hasNext={currentPage < numPages}
        hasPrevious={currentPage > 1}
      />
    </DefaultPollenLayout>
  );
};

export const query = graphql`
  query CategoriesQuery($id: String, $posts: [String]) {
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    wpCategory(id: { eq: $id }) {
      id
      uri
      name
      description
    }
    allWpPost(filter: { id: { in: $posts } }) {
      nodes {
        title
        excerpt
        uri
        featuredImage {
          node {
            altText
            mediaItemUrl
            gatsbyImage(
              width: 800
              placeholder: BLURRED
              formats: [WEBP, AUTO]
              layout: FULL_WIDTH
            )
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [WEBP, JPG, AUTO]
                )
              }
            }
            author {
              node {
                avatar {
                  url
                }
              }
            }
          }
        }
        categories {
          nodes {
            name
          }
        }
        author {
          node {
            avatar {
              url
            }
            uri
            name
          }
        }
      }
    }
  }
`;

export default CategoryTemplate;
