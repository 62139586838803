import React from "react";
import styles from "./SearchInput.module.scss";

interface ISearchInput {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent) => void;
  navigateTo?: string;
}
const SearchInput: React.FC<ISearchInput> = ({ value, onChange, onSubmit }) => {
  return (
    <div data-testid="div:SearchInput" className={styles.searchInput}>
      <form className={styles.searchContainer} onSubmit={onSubmit}>
        <input
          placeholder="Search articles or topics"
          type="search"
          name="search"
          value={value}
          onChange={onChange}
        />
        <div className={styles.searchIcon}></div>
      </form>
    </div>
  );
};

export default SearchInput;
