import React, { memo } from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { ProductAdNode } from "../../../../assets/Types";
import { mxpProductAdClicked } from "../../../../utils/mixpanelEvents/mixpanelEvents";

import styles from "./BlogPM.module.scss";

type IBlogPM = {
  productAd: ProductAdNode;
  className?: string;
  bside?: boolean;
};

const BlogPM: React.FC<IBlogPM> = ({
  productAd,
  className = "",
  bside = false,
}) => {
  const { productAdsC } = productAd;
  const image = getImage(productAdsC.productAdsImage.localFile);
  const bSideStyle = bside ? styles.bSide : "";

  const onClickHandler = (e: any) => {
    mxpProductAdClicked(productAdsC.productAdsLink, productAd.title);
  };

  return (
    <div
      className={`${styles.blogPM} ${className} ${bSideStyle}`}
      data-testid="div:productAd"
    >
      <a
        href={productAdsC.productAdsLink}
        rel="noopener noreferrer"
        onClick={onClickHandler}
      >
        <GatsbyImage
          image={image}
          alt={productAdsC.productAdsImage.altText || ""}
        />
      </a>
    </div>
  );
};

export default memo(BlogPM);
