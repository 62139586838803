import React, { useState } from "react";
import { navigate } from "gatsby";
import BlogHeroHome from "../PollenSectionContainer/BlogGridHome/BlogHeroHome/BlogHeroHome";
import BlogItem from "../../components/BlogItem/BlogItem";
import BlogPM from "../PollenSectionContainer/BlogCards/BlogPM/BlogPM";
import SearchInput from "../../components/SearchInput/SearchInput";
import { PollenPost, ProductAdNode } from "../../assets/Types";
import styles from "./PostsByCategoryContainer.module.scss";

type IPostsByCategoryContainer = {
  posts: PollenPost[];
  productAdsC: ProductAdNode;
  category: {
    name: string;
    description: string;
  };
};
const PostsByCategoryContainer: React.FC<IPostsByCategoryContainer> = ({
  posts,
  productAdsC,
  category,
}) => {
  const [search, setSearch] = useState("");
  const heroPost = posts[0];
  const anotherPosts = posts.slice(1);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSearch(value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    navigate(`/search?term=${search}`);
  };

  return (
    <div className={`full-width ${styles.categoryContainer}`}>
      <header className={`${styles.garamond} ${styles.categoryHeader}`}>
        <h1>{category.name}</h1>
        <h2>{category.description}</h2>
      </header>

      <div className={styles.blogCatGrid}>
        <div className={styles.bclist}>
          <BlogHeroHome blogHero={heroPost} />
          {anotherPosts.map((post, index) => (
            <BlogItem key={index} post={post} />
          ))}
        </div>

        <div className={styles.bcside}>
          <SearchInput
            value={search}
            onChange={handleChange}
            onSubmit={handleSubmit}
          />
          <BlogPM productAd={productAdsC} className={styles.blogProductAds} />
        </div>
      </div>
    </div>
  );
};

export default PostsByCategoryContainer;
