import { graphql, useStaticQuery } from "gatsby";
import { ProductAd } from "../Types";

export const ProductAdQuery = (): { allWpProductAd: ProductAd } => {
  return useStaticQuery(graphql`
    query ProductAdQuery {
      allWpProductAd(
        filter: { productAdsC: { productAdsLink: { ne: null } } }
      ) {
        nodes {
          title
          productAdsC {
            productAdsLink
            productAdsNewTab
            productAdsImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [WEBP, JPG, AUTO]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);
};
